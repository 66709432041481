// npm

// css
import '../../scss/pages/only-footer.scss';

// libs

// utils

document.addEventListener('DOMContentLoaded', () => {});

window.addEventListener('load', () => {});
